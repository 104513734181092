import { Add, Delete, Edit, North, South, UploadFile } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getCompaniesList, getCompaniesListFun, getFilteredCompanies, sortCompanies } from '../../Redux/company/action';
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Companies.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import moment from 'moment';

const VerifyCompaniesData = () => {
    const dispatch = useDispatch();
    const { companies_list, sortBy, sortOrder, isSorted, is_loading } = useSelector((store) => store.companies);

    const [companyUpdateData, setCompanyUpdateData] = useState({
        no_of_shares: 0,
        ttm_pat: 0,
        isin: "",
        symbol: "",
        id: "",
        data_of: "",
        exchange: "",
    });

    useEffect(() => {
        if (!isSorted) {
            dispatch(getCompaniesListFun(is_loading));
        };

        return () => {

        }
    }, []);


    useEffect(() => {
        if (companyUpdateData.id) {
            const updateData = setTimeout(() => {
                sendUpdateRequest();

            }, 1000);

            return () => clearTimeout(updateData);
        }

        return () => {

        }
    }, [companyUpdateData]);

    const updateCompany = (ele, objectId) => {
        const { id, value } = ele.target;

        const updatedData = companies_list.map((item) =>
            item._id === objectId && id ? { ...item, [id]: value } : item
        );

        setCompanyUpdateData({
            ...companyUpdateData,
            id: objectId,
            [id]: value
        });

        dispatch(getCompaniesList(updatedData));
    };


    const renderArrow = () => {
        if (!sortOrder) {
            return <South />
        }
        return <North />
    };


    const sendUpdateRequest = async () => {
        if (!companyUpdateData.id) return;

        const body = {};

        for (let key in companyUpdateData) {
            if (key !== "id" && companyUpdateData[key]) {
                body[key] = companyUpdateData[key];
            };
        };

        const data = await fetch(`https://api.sovrenn.com/company/${companyUpdateData.id}`, {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        })
            .then((res) => res.json());

        if (data.success) {
            console.log("Company edited successfully!")
        }
        else {
            console.log("Ooops something went wrong please try again");
        };

        dispatch(getCompaniesListFun(false));
        return;
    };

    if (is_loading) {
        return (
            <div className={commonStyles.MainContainer}>

                <div className={commonStyles.headerDiv}>
                    <h1>Verify Companies Data</h1>
                </div>

                <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "100px auto" }}>
                    <CircularProgress />
                </Box>
            </div>
        )
    };

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Verify Companies</h1>
            </div>

            <table id={tableStyles.userListContainer}>
                <thead id={tableStyles.userListHead}>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Company Name</th>
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "isin", sortOrder: !sortOrder }))}>
                            <div className={styles.thWrapper}>
                                <h4>ISIN</h4>
                                <h4>{sortBy === "isin" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th> 
                        <th>Symbol</th>
                        <th>Exchange</th>
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "share_price", sortOrder: !sortOrder }))}>
                            <div className={styles.thWrapper}>
                                <h4>Share Price</h4>
                                <h4>{sortBy === "share_price" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th> 
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "no_of_shares", sortOrder: !sortOrder }))}>
                            <div className={styles.thWrapper}>
                                <h4>No of shares</h4>
                                <h4>{sortBy === "no_of_shares" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th>                        
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "market_cap", sortOrder: !sortOrder}))}>
                            <div className={styles.thWrapper}>
                                <h4>Market Cap</h4>
                                <h4>{sortBy === "market_cap" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th>
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "ttm_pe", sortOrder: !sortOrder}))}>
                            <div className={styles.thWrapper}>
                                <h4>TTM PE</h4>
                                <h4>{sortBy === "ttm_pe" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th>
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "ttm_pat", sortOrder: !sortOrder}))}>
                            <div className={styles.thWrapper}>
                                <h4>TTM PAT</h4>
                                <h4>{sortBy === "ttm_pat" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th>
                        <th>Data Of</th>
                        <th className={styles.tableHead} onClick={(ele) => dispatch(sortCompanies({ sortBy: "verified_on", sortOrder: !sortOrder }))}>
                            <div className={styles.thWrapper}>
                                <h4>Verified On</h4>
                                <h4>{sortBy === "verified_on" ? renderArrow() : <div className={styles.extraArrowDiv}></div>}</h4>
                            </div>
                        </th> 
                        <th>Discovery Data</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {companies_list.map((ele, ind) => {
                        return <tr className={tableStyles.userCard} key={ind}>
                            <td>{ind + 1}</td>
                            <td className={styles.name}>{ele.company_name}</td>

                            <td style={{ width: "120px" }}><input type="text" value={ele.isin} onChange={(item) => {
                                updateCompany(item, ele._id)
                            }} id="isin" className={styles.thInput} /></td>

                            <td style={{ width: "120px" }}><input type="text" value={ele.symbol} onChange={(item) => {
                                updateCompany(item, ele._id)
                            }} id="symbol" className={styles.thInput} /></td>

                            <td style={{ width: "70px" }}>
                                <select type="text" value={ele.exchange} onChange={(item) => {
                                    updateCompany(item, ele._id)
                                }} id="exchange" className={styles.thInput} >
                                    <option value=""></option>
                                    <option value="NSE">NSE</option>
                                    <option value="BSE">BSE</option>
                                </select>
                            </td>

                            <td className={styles.name}>{ele.share_price}</td>

                            <td style={{ width: "60px" }}><input type="text" value={ele.no_of_shares} onChange={(item) => {
                                updateCompany(item, ele._id)
                            }} id="no_of_shares" className={styles.thInput} /></td>

                            <td className={styles.name}>{ele.market_cap}</td>

                            <td className={styles.name}>{ele.ttm_pe}</td>

                            <td style={{ width: "70px" }}><input type="number" value={ele.ttm_pat} onChange={(item) => {
                                updateCompany(item, ele._id)
                            }} id="ttm_pat" className={styles.thInput} /></td>

                            <td style={{ width: "100px" }}>
                                <select type="text" value={ele.data_of} onChange={(item) => {
                                    updateCompany(item, ele._id)
                                }} id="data_of" className={styles.thInput} >
                                    <option value=""></option>
                                    <option value="March 23">March 23</option>
                                    <option value="June 23">June 23</option>
                                    <option value="Sep 23">Sep 23</option>
                                    <option value="Dec 23">Dec 23</option>
                                    <option value="March 24">March 24</option>
                                </select>
                            </td>

                            <td className={styles.name}>{ele?.verified_on ? moment(ele.verified_on).format("Do MMM YY") : "NA"}</td>

                            <td className={styles.name}>{ele?.discovery_data_updated_at ? moment(ele.discovery_data_updated_at).format("Do MMM YY") : "NA"}</td>

                            <td>
                                <Link to={`/companies/${ele._id}`}>
                                    <IconButton className={styles.editButton}>
                                        <Edit />
                                    </IconButton>
                                </Link>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default VerifyCompaniesData