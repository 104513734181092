import { Add, Email, Upload } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import commonStyles from "../../styles/Common.module.css"
import styles from "./styles/News.module.css";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress"

const News = () => {
    const [dialog, setDialog] = useState(false);
    const [dialog1, setDialog1] = useState(false);
    const [dialog2, setDialog2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dialogInputRef = useRef();
    const dialog1InputRef = useRef();
    const dateInputRef = useRef();
    const dialogInputRef2 = useRef();
    const dateInputRef1 = useRef();

    const sendDailyMail = async (event) => {
        event.preventDefault();
        if (!dialogInputRef.current.files[0]) {
            return;
        }

        setDialog(false);
        setIsLoading(true);

        const formdata = new FormData();

        formdata.set("news_email_pdf", dialogInputRef.current.files[0]);

        const res = await fetch("https://api.sovrenn.com/news/times/daily-mail", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: formdata
        });

        const data = await res.json();

        setIsLoading(false);

        if (res.ok) {
            alert("Email sent successfully.")
            return;
        }

        alert("Something went wrong.");
        return;
    }

    const addTodaysTimesPdf = async (event) => {
        event.preventDefault();
        if (!dialogInputRef2.current.files[0]) {
            alert("Select pdf file and date")
            return;
        }

        setDialog2(false);
        setIsLoading(true);

        const formdata = new FormData();

        formdata.set("news_email_pdf", dialogInputRef2.current.files[0]);
        formdata.set("date", dateInputRef1.current.value);

        const res = await fetch("https://api.sovrenn.com/news/times/pdf", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: formdata
        });

        const data = await res.json();

        setIsLoading(false);

        if (res.ok) {
            alert("Times PDF uploaded successfully.")
            return;
        }

        alert("Something went wrong.");
        return;
    }

    const uploadFreePdf = async (event) => {
        event.preventDefault();
        if (!dialog1InputRef.current.files[0]) {
            alert("PDF File not selected")
            return;
        };

        if (!dateInputRef.current.value) {
            alert("Select the date of article")
            return;
        };

        setDialog1(false);

        setIsLoading(true);

        const formdata = new FormData();

        formdata.set("news_email_pdf", dialog1InputRef.current.files[0]);
        formdata.set("news_date", dateInputRef.current.value);

        const res = await fetch("https://api.sovrenn.com/times-free-pdfs/new", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: formdata
        });

        const data = await res.json();

        setIsLoading(false);

        if (res.ok) {
            alert("PDF uploaded successfully.")
            return;
        };

        alert("Something went wrong, please try again later");
        return;
    }

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>News</h1>

                <div>

                    <Button color="secondary" variant="outlined" startIcon={<Upload />} fullWidth sx={{ width: "150px" }} onClick={() => {
                        setDialog1(true);
                    }}>Free PDF</Button>

                    <Button color="secondary" variant="outlined" startIcon={<Email />} fullWidth sx={{ width: "150px" }} onClick={() => {
                        setDialog(true);
                    }}>Daily Mail</Button>

                    <Button color="success" variant="outlined" startIcon={<Upload />} fullWidth sx={{ width: "190px" }} onClick={() => {
                        setDialog2(true);
                    }}>Upload Times PDF</Button>

                    <Link to="/news/new">
                        <Button color="success" variant="outlined" startIcon={<Add />} fullWidth sx={{ width: "175px" }}>Upload Times</Button>
                    </Link>
                </div>
            </div>

            <OverlayCircularProgress loading={isLoading} />

            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Select File(.pdf)</DialogTitle>
                <DialogContent >
                    <input type="file" ref={dialogInputRef} accept="application/pdf" id={styles.fileInput} />
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={sendDailyMail}>Send</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialog1} onClose={() => setDialog1(false)}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Select File(.pdf) & Date of Article</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        <input style={{ fontSize: "large" }} type="file" ref={dialog1InputRef} accept="application/pdf" id={styles.fileInput} required />
                        <input ref={dateInputRef}  style={{ height: "40px", fontSize: "x-large" }} type="date" required />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={uploadFreePdf}>Send</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialog2} onClose={() => setDialog2(false)}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Select File(.pdf) & Date of Article</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        <input style={{ fontSize: "large" }} type="file" ref={dialogInputRef2} accept="application/pdf" id={styles.fileInput} required />
                        <input ref={dateInputRef1}  style={{ height: "40px", fontSize: "x-large" }} type="date" required />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={addTodaysTimesPdf}>Send</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default News