import { CircularProgress, Dialog, DialogContent, Divider, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../Redux/Users/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/UserDetails.module.css";
import tableStyles from "./styles/Users.module.css";
import { Box, Modal } from "@mui/material";
import modalStyles from "../../styles/ModalStyle.module.css";

const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$');

const activity_type = {
  login: "Login",
  article_read: "Article Read",
  page_visited: "Page Visited",
  tool_used: "Used Self Help Tool",
  purchase: "Tried to Purchase",
  times: "Times",
  discovery: "Discovery",
  prime: "Prime",
  prime_article: "Prime",
  pulse: "Pulse",
  promoter_interview: "Promoter Interview",
  ipo: "IPO",
  macro: "Macro",
  app: "Mobile App",
  website: "Website",
}

export const UserDetails = () => {
  const dispatch = useDispatch();
  const { selectedUser } = useSelector((store) => store.users);
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [removeAccessModalOpen, setRemoveAccessModalOpen] = useState(false);
  const [manualAmount, setManualAmount] = useState(0);
  const [resetPassData, setResetPassData] = useState({
    password: "",
    conf_password: "",
  });

  const [giveAccessProgress, setGiveAccessProgress] = useState(false);

  useEffect(() => {
    dispatch(getUserDetails(id));
    getSearchHistory(id);
    window.scrollTo(0, 0);

    return () => {

    }
  }, []);

  const giveAccessFun = async (type, id, validity_in_months, free_access,) => {

    setGiveAccessProgress(true);

    const res = await fetch(`https://api.sovrenn.com/manual/${type}/${id}?amount=${manualAmount}&free_access=${free_access}&validity_in_months=${validity_in_months}`, {
      method: "PATCH",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      }
    })
      .then((r) => r.json());

    setGiveAccessProgress(false);

    if (res.success) {
      setManualAmount(0);
      dispatch(getUserDetails(id));
      setModalOpen(false);
      alert("Access has been given succssfully");
      return;
    };

    alert("Something went wrong");
    return;
  };

  const getSearchHistory = async (id) => {

    const res = await fetch(`https://api.sovrenn.com/search-history/${id}`, {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      }
    })
      .then((r) => r.json());


    if (res.success) {
      setSearchHistory(res.data);
    };

    return;
  };


  const removeAccessFun = async (type, id) => {

    const res = await fetch(`https://api.sovrenn.com/manual/remove-access`, {
      method: "PATCH",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("sov_user"),
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        access: type,
        user_id: id,
        amount: manualAmount
      })
    })
      .then((r) => r.json());

    if (res.success) {
      setManualAmount(0);
      dispatch(getUserDetails(id));
      setRemoveAccessModalOpen(false);
      alert(res.message);
      return;
    };

    alert("Something went wrong");
    return;
  };


  const resetPassFun = async (event) => {
    event.preventDefault();


    if (resetPassData.password !== resetPassData.conf_password) {
      alert("Confirm password doesn't match New password.");
      return;
    };

    if (!validPassword.test(resetPassData.password)) {
      alert("Password must be of min 8 characters and one numeric digit, one lowercase letter.");
      return;
    };

    const res = await fetch("https://api.sovrenn.com/user/reset-pass-admin", {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("sov_user")
      },
      body: JSON.stringify({
        password: resetPassData.password,
        user_id: selectedUser.user._id
      })
    });

    const data = await res.json();

    if (res.ok) {
      alert(data.message);
      setResetPassData({
        password: "",
        conf_password: ""
      });

      return;
    };

    alert("Sorry, Something went wrong.");
    return;
  };

  return (
    <div className={`${commonStyles.MainContainer}`} >
      <div className={commonStyles.headerDiv}>
        <h1>{selectedUser?.user?.first_name} {selectedUser?.user?.last_name}</h1>

      </div>

      <div id={styles.userDataContainer}>
        <div className={styles.infoCard}>
          <h4>Email: </h4>
          <h3>{selectedUser?.user?.email}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>Phone Number: </h4>
          <h3>{selectedUser?.user?.country_code} {selectedUser?.user?.phone_number}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>State: </h4>
          <h3>{selectedUser?.user?.state}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>Total Paid: </h4>
          <h3>₹{selectedUser?.user?.total_spent}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>Has access to: </h4>
          {selectedUser?.user?.subscriptions?.length > 0 ?
            (selectedUser?.user?.subscriptions?.map((ele, ind) => {
              return <h3 className={styles.accessLinks}>{ele === "full-access" ? "Full Access to Sovrenn" : ele === "trial" ? "Trial Access to Sovrenn" : ele === "life" ? "Full Access to Sovrenn for 3/5 years" : ele === "monthly" ? "Full Access to Sovrenn for 1 month" : ele === "quarterly" ? "Full Access to Sovrenn for 3 months" : ele === "news" ? "Access to Information" : ele === "basket" ? "Access to Stock Discovery" : "Not subscribed"}</h3>
            }))

            :
            <h3 className={styles.accessLinks}>Not Subscribed</h3>}

          <div className={styles.accessDiv}>
            <button onClick={() => setModalOpen(true)}>Give Access</button>

            {selectedUser?.user?.subscriptions?.length > 0 ?
              <button onClick={() => setRemoveAccessModalOpen(true)}>Remove Access</button>
              :
              ""}
          </div>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>Signed up Date: </h4>
          <h3>{moment(selectedUser?.user?.createdAt).format("Do MMM YYYY")}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>App Installed: </h4>
          <h3>{selectedUser?.user?.has_app_installed ? "Yes" : "No"}</h3>,

          <h4>Pulse Portfolio Exists: </h4>
          <h3>{selectedUser?.user?.my_portfolio_exists ? "Yes" : "No"}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>Where Did Hear About Sovrenn: </h4>
          <h3>{selectedUser?.user?.where_did_hear_about_sovrenn || "NA"}</h3>
        </div>

        <Divider />

        <div className={styles.infoCard}>
          <h4>Total Action Counts: </h4>
          <h3>{selectedUser?.user?.action_counts}</h3>
        </div>

        <Divider />

        {selectedUser.trialDate ?
          <div className={styles.infoCard}>
            <h4>Trial Access Purchased On: </h4>
            <h3>{moment(selectedUser.trialDate).format("Do MMM YYYY")}</h3>
          </div>
          :
          ""}

        {selectedUser.trialDate ?
          <Divider /> : ""
        }

        <div className={styles.infoCard}>
          <h4>Remark: </h4>
          <input type="text" value={selectedUser?.user?.remark || ""} />
        </div>

        <Divider />


        <div className={styles.infoCard}>
          <h4>Reset Password: </h4>
          <TextField id="outlined-basic" value={resetPassData.password} label="New Password" variant="outlined" size="small" name="password" onChange={(ele) => {
            setResetPassData({
              ...resetPassData,
              password: ele.target.value
            })
          }} required />
          <TextField id="outlined-basic" value={resetPassData.conf_password} label="Confirm Password" variant="outlined" size="small" name="conf_password" onChange={(ele) => {
            setResetPassData({
              ...resetPassData,
              conf_password: ele.target.value
            })
          }} required />

          <button className={styles.accessBtn} onClick={resetPassFun}>Reset</button>
        </div>

        <Divider />


      </div>

      <div className={styles.userOrdersTableDiv}>
        <h2>Last Subscriptions</h2>

        <table id={tableStyles.userListContainer}>
          <thead id={tableStyles.userListHead}>
            <tr className={tableStyles.userCard}>
              <th>Sr. No</th>
              <th>Access</th>
              <th>Bought On</th>
              <th>Valid From</th>
              <th>Valid Upto</th>
              <th>Amount Paid</th>
              <th>Purchased From</th>
            </tr>
          </thead>

          <tbody>
            {selectedUser?.subscriptions?.map((ele, ind) => {
              return <tr className={tableStyles.userCard} key={ele._id}>
                <td>{ind + 1}</td>
                <td>{ele.type}</td>
                <td>{moment(ele.createdAt).format("Do MMM YY")}</td>
                <td>{moment(ele.start_date).format("Do MMM YY")}</td>
                <td>{moment(ele.expiry_date).format("Do MMM YY")}</td>
                <td>₹{ele.amount}</td>
                <td>{ele?.bought_from || "NA"}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.userOrdersTableDiv}>
        <h2>Last Prime Orders</h2>

        <table id={tableStyles.userListContainer}>
          <thead id={tableStyles.userListHead}>
            <tr className={tableStyles.userCard}>
              <th>Sr. No</th>
              <th>Company</th>
              <th>Bought On</th>
              <th>Amount Paid</th>
            </tr>
          </thead>

          <tbody>
            {selectedUser?.lastPrimeOrders?.map((ele, ind) => {
              return <tr className={tableStyles.userCard} key={ele._id}>
                <td>{ind + 1}</td>
                <td>{ele.company}</td>
                <td>{moment(ele.createdAt).format("Do MMM YY")}</td>
                <td>₹{ele.amount}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.userOrdersTableDiv}>
        <h2>Last Education Sessions</h2>

        <table id={tableStyles.userListContainer}>
          <thead id={tableStyles.userListHead}>
            <tr className={tableStyles.userCard}>
              <th>Sr. No</th>
              <th>Course</th>
              <th>Time</th>
              <th>Date</th>
              <th>Trainer</th>
              <th>Amount Paid</th>
            </tr>
          </thead>

          <tbody>
            {selectedUser?.lastCourses?.map((ele, ind) => {
              return <tr className={tableStyles.userCard} key={ele._id}>
                <td>{ind + 1}</td>
                <td>{ele.type}</td>
                <td>{ele.start_time} - {ele.end_time}</td>
                <td>{moment(ele.createdAt).format("Do MMM YY")}</td>
                <td>{ele.trainer}</td>
                <td>₹{ele.amount}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.userOrdersTableDiv}>
        <h2>User Search History (Last 7 days)</h2>

        <table className={styles.userActivityTable}>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Company Name</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {searchHistory?.map((ele, ind) => {
              return <tr>
                <td>{ind + 1}</td>
                <td>
                  {ele.company_name}
                </td>

                <td>
                  {ele.count}
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.userOrdersTableDiv}>
        <h2>User Activity (Last 90 days)</h2>

        <table className={styles.userActivityTable}>
          <thead>
            <tr>
              <th>Date Time</th>
              <th>Activity Type</th>
              <th>Details</th>
              <th>Platform</th>
            </tr>
          </thead>
          <tbody>
            {selectedUser?.userHistory?.map((ele, ind) => {
              return <tr>
                <td>{moment(ele.createdAt).format("Do MMM YYYY")} | {moment(ele.createdAt).format('LT')}</td>
                <td>
                  {activity_type[ele.activity_type]}
                </td>

                <td>
                  {ele?.details?.page_name ? (ele?.details?.child_page ? activity_type[ele?.details?.page_name] + " - " + ele?.details?.child_page : activity_type[ele?.details?.page_name]) : ele?.details?.article_type ? activity_type[ele?.details?.article_type] + " - " + ele?.details?.company_name : ele?.details?.action ? ele?.details?.action : "-"}
                </td>

                <td>
                  {activity_type[ele?.platform] || "-"}
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className={modalStyles.InfoModal}>
          <h3>{selectedUser?.user?.first_name} {selectedUser?.user?.last_name}</h3>

          <div className={modalStyles.spanDiv}>
            <h4>E-mail: <span>{selectedUser?.user?.email}</span></h4>
            <h4>Phone: <span>{selectedUser?.user?.phone_number}</span></h4>
          </div>

          <div id={modalStyles.amountSelect}>
            <label htmlFor="manualAmount">Free Or Paid (select amount):</label>

            <input id="manualAmount" value={manualAmount} type="number" onChange={(ele) => setManualAmount(ele.target.value)} />
          </div>

          <div id={modalStyles.submitBtnDiv}>
            <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("full-access", selectedUser?.user?._id, 12)}>Give Full Access</button>

            {selectedUser?.user?.trial_expired || selectedUser?.user?.subscriptions.includes("trial") ? "" :
              <button className={modalStyles.submitBtn} onClick={() => giveAccessFun("trial", selectedUser?.user?._id, 45)}>Give Trial Access</button>
            }

            <button className={modalStyles.submitBtn}
              onClick={() => giveAccessFun("news", selectedUser?.user?._id, 12)}>Give News Access</button>

            <button className={modalStyles.submitBtn}
              onClick={() => giveAccessFun("life", selectedUser?.user?._id, 5)}>Give Life Access</button>
          </div>

          <div id={modalStyles.submitBtnDiv}>
            <button style={{ marginTop: "20px" }} className={modalStyles.submitBtn}
              onClick={() => giveAccessFun("full-access", selectedUser?.user?._id, 3)}>Give Full Access (3 Months)</button>

            <button style={{ marginTop: "20px" }} className={modalStyles.submitBtn}
              onClick={() => giveAccessFun("news", selectedUser?.user?._id, 6)}>Give News Access (6 Months)</button>
          </div>

        </Box>
      </Modal>

      <Modal open={removeAccessModalOpen} onClose={() => setRemoveAccessModalOpen(false)}>
        <Box className={modalStyles.InfoModal}>
          <h3>Remove Access</h3>

          <div id={modalStyles.amountSelect}>
            <label htmlFor="manualAmount">Enter amount to minus:</label>

            <input id="manualAmount" value={manualAmount} type="number" onChange={(ele) => setManualAmount(ele.target.value)} />
          </div>

          <div id={modalStyles.submitBtnDiv}>


            {selectedUser?.user?.subscriptions?.map((ele, ind) => {
              return <button className={modalStyles.submitBtn} onClick={() => removeAccessFun(ele, selectedUser?.user?._id)}>Remove {ele === "full-access" ? "Full" : ele === "quarterly" ? "Quarterly" : ele === "news" ? "Times" : ele === "monthly" ? "Monthly" : ele === "basket" ? "Discovery" : ele === "trial" ? "Trial" : ""} Access</button>
            })}
          </div>
        </Box>
      </Modal>


      <Dialog open={giveAccessProgress} disableBackdropClick disableEscapeKeyDown>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <CircularProgress color="secondary" style={{ width: "50px", height: "50px" }} />
          <h3 style={{ textAlign: 'center', marginTop: '20px' }}>
            Please wait...
          </h3>
        </DialogContent>
      </Dialog>
    </div>
  )
}
